// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import copy from 'copy-to-clipboard';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'status', 'value'];

  connect() {
    this.buttonTarget.addEventListener('click', (event) => this.doCopy(event));
  }

  doCopy(event) {
    if (event) {
      event.preventDefault();
    }
    this.buttonTarget.hidden = true;
    this.statusTarget.hidden = false;
    copy(this.valueTarget.value);
    setTimeout(() => {
      this.buttonTarget.hidden = false;
      this.statusTarget.hidden = true;
    }, 3000);
  }
}
