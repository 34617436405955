import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Rails from '@rails/ujs';
import LocalTime from 'local-time';
import 'chartkick/chart.js';
import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
import '../shared/styles/portal/index.scss';
import feather from 'feather-icons';

Rails.start();
LocalTime.start();

const application = Application.start();
const context = require.context(
  'affiliates/controllers',
  true,
  /_controller\.jsx?$/
);
application.load(definitionsFromContext(context));

// INIT
window.addEventListener('load', function () {
  setTimeout(() => feather.replace());
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="collapse"]'),
    function (element) {
      new Collapse(element);
    }
  );
});
